import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  /**
   * Lista os feriados.
   */
  get(params) {
    return http.api.get(VERSION + "/assets", { params });
  },

}
