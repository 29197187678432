<!-- Lista os assets -->
<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card color="grey lighten-2">
      <v-toolbar>
        <!-- Campo de Pesquisa -->
        <v-text-field
          flat
          hide-details
          solo-inverted
          class="ml-10"
          placeholder="Pesquisar"
          prepend-inner-icon="mdi-magnify"
          v-model="textFieldSearch"
        ></v-text-field>

        <v-select
          hide-details
          class="ma-1 pa-3"
          item-value="value"
          label="Ordenar por"
          :items="assetOrders"
          v-model="filterAssetOrder"
          @change="search()"
          style="max-width: 200px"
        ></v-select>

        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          :href="assetsAppUrl + '/asset/new?type=JINJA'"
          target="_blank"
        >
          Criar Novo
        </v-btn>

        <v-btn
          icon
          class="ml-2"
          @click="updateAsset"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <!-- Botao para fechar a tela -->
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-alert v-if="loadingError" type="error" prominent text>
        <v-row align="center">
          <v-col class="grow"> Não foi possível carregar os assets. </v-col>
          <v-col class="shrink">
            <v-btn color="primary" @click="updateAsset()">
              Tentar novamente
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <div style="height: calc(100vh - 48px); overflow-y: auto" v-if="!loadingError">
        <v-row class="ma-0">
          <!-- Loading -->
          <v-col cols="12" v-if="loading">
            <div class="d-flex align-center justify-center qj-loading">
              <v-progress-circular
                indeterminate
                color="primary"
                :size="40"
              ></v-progress-circular>
            </div>
          </v-col>

          <!-- Cards Grid -->
          <v-col
            cols="12"
            md="4"
            xl="3"
            v-else-if="assets.length"
            v-for="asset in assetsFiltered"
            v-bind:key="asset.path"
          >
            <v-card class="fill-height">
              <v-hover v-slot="{ hover }">
                <v-img
                  height="150"
                  position="left top"
                  :src="asset.preview_url"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        :width="2"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-fade-transition>
                    <div
                      v-if="hover"
                      class="
                        d-flex
                        transition-fast-in-fast-out
                        v-card--reveal
                        justify-center
                        align-center
                        white--text
                      "
                      style="height: 100%; background-color: rgba(0, 0, 0, 0.3)"
                    >
                      <!-- Botao para visualizar a imagem inteira -->
                      <v-btn color="white" :href="asset.url + '?use_preview_params=true'" target="_blank">
                        Visualizar
                      </v-btn>
                    </div>
                  </v-fade-transition>
                </v-img>
              </v-hover>

              <v-card-title>
                <span class="text-subtitle-2">
                  {{ asset.path }}
                </span>
              </v-card-title>

              <v-card-subtitle v-if="asset.observation">
                <span v-html="asset.observation"></span>
              </v-card-subtitle>

              <v-divider></v-divider>

              <!-- Copy button  -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="selectedUrl(asset.url)"
                  text
                  color="primary"
                >
                  Importar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- Exibe mensagem 'Nao encontrado' -->
          <v-col cols="12" class="text-center" v-else
            >Nenhum asset encontrado</v-col
          >
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import asset from "@/services/asset";
import enviroment from "../assets/js/enviroment";

export default {
  props: ["dialog"],
  data: () => ({
    loadingError: false,
    assets: [],
    assetsAppUrl: enviroment.assetsAppUrl,
    loading: false,
    textFieldSearch: "",
    filterAssetOrder: "-created_at",
    assetOrders: [
      { text: "Criação", value: "-created_at" },
      { text: "Atualização", value: "-updated_at" },
    ],
  }),

  methods: {
    updateAsset() {
      asset
        .get({ limit: 1000, order: this.filterAssetOrder })
        .then((response) => {
          this.assets = response.data;
          this.loadingError = false;
        })
        .catch(() => {
          this.loadingError = true;
        });
    },
    close() {
      this.$emit("update:dialog", false);
    },
    cleanFilters() {
      this.filterAssetOrder = "-created_at";
    },
    search() {
      this.loading = true;
      asset
        .get({ limit: 1000, order: this.filterAssetOrder })
        .then((response) => {
          this.assets = response.data;
        })
        .catch(() => {
          this.loadingError = true;
        })
        .finally(() => (this.loading = false));
    },
    selectedUrl(url) {
      this.$emit("selectedUrl", url);
      this.close();
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.loading = true;
        asset
          .get({ limit: 1000, order: this.filterAssetOrder })
          .then((response) => {
            this.assets = response.data;
          })
          .catch(() => {
            this.loadingError = true;
          })
          .finally(() => (this.loading = false));
      }
    },
  },

  computed: {
    assetsFiltered() {
      return this.assets.filter((asset) => {
        if (asset.path.indexOf(this.textFieldSearch) > -1) return true;
        return false;
      });
    },
    haveFilters() {
      return this.filterAssetOrder == "-created_at" ? false : true;
    },
  },
};
</script>

<style scoped lang="scss">
.qj-loading {
  width: 100%;
  height: calc(100vh - 168px);
}
</style>
